<template>
  <GraphQLReportList
    v-bind="$attrs"
    :graphql-query="query"
    :fixed-filters="fixedFilters"
    :no-data-label="t('No Tasks')"
    :storage-prefix="storagePrefix"
  >
    <template #item="{ item }">
      <TransferTaskListItem
        :transfer-task="item"
        clickable
        @click="handleItemClick(item)"
      />
    </template>
  </GraphQLReportList>
  <TransferTaskDialog
    v-if="transferTask"
    :transfer="transferTask"
    :model-value="!!transferTask"
    @update:model-value="transferTask = null"
  >
    <template #error>
      <PrimaryErrorBanner />
    </template>
    <template #product-count>
      {{ transferredAmount }} / {{ plannedAmount }}
    </template>
    <template #buttons="{ buttonProps }">
      <QBtn
        v-bind="buttonProps"
        icon="mdi-play"
        :disable="!canCreateTransfer"
        @click="createTransferByTask(transferTask!)"
      >
        {{ t('Create Transfer') }}
      </QBtn>
    </template>
  </TransferTaskDialog>
</template>

<script setup lang="ts">

import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import type { MutationCreateTransferByTaskArgs, Transfer, TransferTask } from '@/graphql/types';
import { type ReportFilterInput, TransferStateEnum } from '@/graphql/types';
import TransferTaskDialog from '@/views/Mobile/Transfer/TransferTaskDialog.vue';
import { gql, useClientHandle } from '@urql/vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import TransferTaskListItem from '@/views/Mobile/TransferTask/TransferTaskListItem.vue';
import StorageForTransfer from '@/graphql/fragments/StorageForTransfer';
import * as R from 'ramda';
import ROUTES from '@/router/routeNames';
import useErrorHandling from '@/composables/useErrorHandling';

const { t } = useI18n();

const props = defineProps<{
  states: TransferStateEnum[];
  storagePrefix: string;
}>();

const router = useRouter();
const { client: urql } = useClientHandle();
const { clearErrors, fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();


const query = gql`
  query TransferTasksForDashboard(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: transferTasksList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          __typename
          id
          createdAt
          state
          user {
            id
            lastname
            firstname
          }
          items {
            id
            productPack {
              id
              quantityInMinMeasurementUnits
              measurementUnit { id name shortName isFractional }
              product { id name sku photos { id url(size: SMALL) } }
            }
            storageFrom {
              ...StorageForTransfer
            }
            storageTo {
              ...StorageForTransfer
            }
            plannedAmount
            transferredAmount
            amountInTransfers
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${StorageForTransfer}

`;

const fixedFilters = computed<ReportFilterInput[]>(() => [{
  field:    'state',
  operator: 'in',
  value:    JSON.stringify(props.states),
}]);

const transferTask = ref<TransferTask | null>(null);

function handleItemClick(item: TransferTask): void {
  clearErrors();
  transferTask.value = item;
}

async function createTransferByTask(task: TransferTask) {
  clearErrors();
  const { error, data } = await urql.mutation<{transfer: Transfer}, MutationCreateTransferByTaskArgs>(
    gql`
      mutation CreateTransferByTask($transferTaskId: ID!) {
        transfer: createTransferByTask(transferTaskId: $transferTaskId) {
          id
        }
      }
    `,
    {
      transferTaskId: task.id,
    },
  );

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  router.push({ name: ROUTES.TRANSFER_PROCESS, params: { transferId: data!.transfer.id } });
}

const canCreateTransfer = computed(() => transferTask.value!.state !== TransferStateEnum.COMPLETED
    && transferTask.value!.items.some((i) => i.plannedAmount > i.amountInTransfers));

const transferredAmount = computed(() => R.sum(transferTask.value?.items.map(
  i => i.productPack.quantityInMinMeasurementUnits * i.transferredAmount,
) ?? []));

const plannedAmount = computed(() => R.sum(transferTask.value?.items.map(
  i => i.productPack.quantityInMinMeasurementUnits * i.plannedAmount,
) ?? []));

</script>

<i18n lang="yaml">
ru:
  Create Transfer: Создать перемещение
  No Tasks: Задания отсутствуют

en:
  Create Transfer: Create Transfer
  No Tasks: No Tasks

</i18n>
