<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="fetching"
        class="text-center"
      >
        <BigLoading />
      </QCardSection>
      <template v-else-if="transferTask">
        <CardTitle>
          {{
            t('{id} dated {date}', {
              id: id,
              date: formatDate(transferTask.createdAt, FORMATS.DATETIME),
            })
          }}
          <QChip
            :color="badgeColors.forTransferTask(transferTask)"
            text-color="black"
          >
            {{ t(`transferState.${transferTask.state}`) }}
          </QChip>
        </CardTitle>

        <QCardSection>
          <div class="row q-col-gutter-sm">
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <QField
                :label="t('User')"
                stack-label
              >
                <template #control>
                  {{ userFullName(transferTask.user) }}
                </template>
              </QField>
            </div>
          </div>
        </QCardSection>

        <TransferTaskItems :task="transferTask" />

        <QSeparator />

        <QCardActions>
          <QBtn
            exact
            icon="mdi-arrow-left"
            @click="navigateBack({name: 'Transfer Tasks'})"
          >
            {{ t('Back') }}
          </QBtn>

          <QSpace />
        </QCardActions>
      </template>
      <BaseAlert
        v-else
        type="info"
        icon="mdi-run-fast"
      >
        <p>
          {{ t('Transfer Task not found') }}
        </p>
        <QBtn
          exact
          color="primary"
          icon="mdi-arrow-left"
          :to="{ name: 'Transfer' }"
        >
          {{ t('Go to Transfer Tasks List') }}
        </QBtn>
      </BaseAlert>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { TransferTask } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import userFullName from '@/helpers/userFullName';
import { gql, useQuery } from '@urql/vue';
import { QField  } from 'quasar';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import navigateBack from '@/helpers/navigateBack';
import TransferTaskItems from '@/views/Storage/TransferTaskItems.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';

const { t } = useI18n();

useBreadcrumbs([t('Transfer Tasks'), t('Edit')]);

const { formatDate } = useLocalizedFormatters();

const { fillErrorsFromGraphQLError, getPrimaryError } = useErrorHandling();

const props = defineProps<{
  id: string;
}>();

const transferFragment = gql`
  fragment TransferTaskForCard on TransferTask {
      id
      createdAt
      state
      user { id firstname lastname }
      items {
        id
        transferredAmount
        plannedAmount
        storageFrom { id name }
        storageTo { id name }
        productPack {
          id
          deletedAt
          quantity
          product {
            id
            sku
            name
          }
          measurementUnit { id name shortName }
        }
      }
  }
`;

const { data, error, fetching } = useQuery<{ transferTask: TransferTask }>({
  query: gql`
    ${transferFragment}
    query GetTransferTaskForCard($id: ID!) { transferTask(id: $id) {
     ...TransferTaskForCard
    } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});
watch(error, fillErrorsFromGraphQLError);

const transferTask = computed(() => data.value?.transferTask ?? null);

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/transfer.yaml"></i18n>

<i18n lang="yaml">
ru:
  Transfer Task not found: Задание на перемещение не найдено
  Go to Transfer Tasks List: К списку заданий на перемещение
  Transfer {id} dated {date}: Перемещение {id} от {date}

en:
  Transfer Task not found: Transfer Tasks not found
  Go to Transfer Tasks List: Go to Transfer Tasks List
  Transfer {id} dated {date}: Transfer {id} dated {date}
</i18n>
