<template>
  <BaseAlert
    v-if="primaryError"
    type="error"
  >
    {{ primaryError }}
  </BaseAlert>
  <ScrollCarousel
    v-if="store.carouselItems.length > 0"
    v-model="store.selectedItemIndex"
    :items="store.carouselItems"
    dont-reset-on-items-change
  >
    <template #item="{ item, index }">
      <SelectionCarouselSlide
        :item="item"
        :index="index"
        :display-amount="index === store.selectedItemIndex ? currentAmount : item.takenAmount"
        @delete="emit('delete-selected')"
        @product-click="editingProduct = $event"
        @update:slide="store.selectedItemIndex = $event"
      />
    </template>
    <template #indicators="{index, total}">
      <ScrollCarouselCounter
        :current="index + 1"
        :total="total"
        :suggested="store.currentItemIndex + 1"
        @to-current="store.resetCarouselSliderPosition()"
      />
    </template>
  </ScrollCarousel>
  <div class="q-pa-lg justify-center">
    <BaseScanField
      v-if="store.selectedItem"
      ref="scanField"
      :hint="t('Scan and take needed Product')"
      :search-fn="searchStorageUnit"
      :placeholder="t('Product')"
      :not-found-message="t('Wrong Product')"
      :loading="store.savingMovements"
      no-omni-input-scan
      @update:scanning="$event && handleBeforeScan()"
      @scan="handleStorableScan"
    />
  </div>
  <ProductEditDialog
    v-if="editingProduct"
    :id="editingProduct.id"
    @cancel="editingProduct = null"
  />
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BaseScanField from '@/components/Mobile/BaseScanField.vue';
import ScrollCarousel from '@/components/Mobile/ScrollCarousel.vue';
import ScrollCarouselCounter from '@/components/Mobile/ScrollCarouselCounter.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useSpeaker from '@/composables/useSpeaker';
import type {
  Product,
  ProductPack,
  ProductPackWithAmount,
  QueryProductPackByBarcodeArgs,
} from '@/graphql/types';
import getPackQuantityInBiggerPack from '@/helpers/getPackQuantityInBiggerPack';
import { speechOnProductPackScan } from '@/helpers/speechOnScan';
import useSelectionStore from '@/stores/selection';
import ProductEditDialog from '@/views/Mobile/ProductEditDialog.vue';
import SelectionCarouselSlide from '@/views/Mobile/Selection/SelectionCarouselSlide.vue';
import { gql, useClientHandle } from '@urql/vue';
import { useEventBus } from '@vueuse/core';
import { sortBy } from 'ramda';
import { type Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const speaker = useSpeaker();

const store = useSelectionStore();

const { primaryError, fillErrorsFromGraphQLError, getPrimaryErrorFromGraphQLError, clearErrors } = useErrorHandling();

defineProps<{
  currentAmount: string;
}>();

const emit = defineEmits<{
  (e: 'delete-selected'): void;
  (e: 'before-scan'): void;
  (e: 'scan'): void;
}>();

const { client: urql } = useClientHandle();

async function searchStorageUnit(barcode: string): Promise<ProductPack | null> {
  const exactPackItem = store.carouselItems
    .find(item => item.storageUnit.productPack.barcodes.some(b => b.barcode === barcode));

  if (exactPackItem) {
    return exactPackItem.storageUnit.productPack;
  }

  const { data, error } = await urql.query<{
    productPackByBarcode: ProductPackWithAmount;
  }, QueryProductPackByBarcodeArgs>(
    gql`
      query SearchProductPackForSelection($barcode: String!) {
        productPackByBarcode(barcode: $barcode) {
          productPack {
            id
            quantity
            smallerProductPack { id }
            product {
              id
              name
              productPacks {
                id
                quantity
                smallerProductPack { id }
              }
            }
          }
        }
      }
    `,
    { barcode },
  );

  if (error) {
    throw new Error(getPrimaryErrorFromGraphQLError(error));
  }

  if (!data?.productPackByBarcode) {
    return null;
  }

  const scannedPack = data?.productPackByBarcode.productPack;

  const productFound = store.carouselItems
    .some(item => item.storageUnit.productPack.product.id === scannedPack.product.id);

  return productFound ? scannedPack : null;
}

const { emit: emitCloseDialogs } = useEventBus('close-dialogs');

function handleBeforeScan() {
  emitCloseDialogs();
  emit('before-scan');
}

const lastScannedPack = ref<ProductPack | null>(null);

async function handleStorableScan(scannedPack: ProductPack): Promise<void> {
  clearErrors();

  const itemsWithProduct = sortBy(
    // Сортируем элементы:
    // - для поиска подходящего для отбора
    // - для вывода ошибки, если подходящий не будет найден
    // Приоритет у элементов со сканированной упаковкой.
    // После него будут элементы с другими упаковками того же товара.
    item => item.storageUnit.productPack.id === scannedPack.id ? 0 : 1,
    store.carouselItems.filter(item => item.storageUnit.productPack.product.id === scannedPack.product.id),
  );

  // Ищем элемент, для которого отобранное количество
  // (с учетом количества в сканированной упаковке) укладывается в неотобранное.
  let scannedItem = itemsWithProduct.find(item => {
    // Если сканирована бо́льшая упаковка, нужно взять количество в этой упаковке.
    const scannedAmount = getPackQuantityInBiggerPack(item.storageUnit.productPack, scannedPack)
      ?? 1;
    return item.takenAmount + scannedAmount <= item.plannedAmount;
  }) ?? null;

  // Если найден элемент с другой упаковкой того же товара (со сканированной не найден),
  // нужно конвертировать упаковку в сканированную.
  if (scannedItem && scannedPack.id !== scannedItem.storageUnit.productPack.id) {
    try {
      scannedItem = await store.convertItemPack(scannedItem, scannedPack, 1);
    } catch (error) {
      fillErrorsFromGraphQLError(error);
      speaker.speak(primaryError.value);
      store.selectItemOnCarousel(scannedItem!);
      return;
    }
  }

  if (!scannedItem) {
    const itemForError = itemsWithProduct[0];

    speaker.speak(t(
      'Excess product, only {needed} needed',
      { needed: itemForError.plannedAmount },
    ));
    store.selectItemOnCarousel(itemForError);
    return;
  }

  if (scannedPack.id !== lastScannedPack.value?.id) {
    speaker.speak(speechOnProductPackScan(scannedPack));
    lastScannedPack.value = scannedPack;
  }

  const existingMovement = store.itemMovement(scannedItem, store.currentSelection!.movements);

  const scannedAmount = getPackQuantityInBiggerPack(scannedItem.storageUnit.productPack, scannedPack)
    ?? 1;

  if (existingMovement) {
    const { error } = await store.updateMovementAmount({
      movement: existingMovement,
      amount:   existingMovement.amount + scannedAmount,
    });

    if (error) {
      fillErrorsFromGraphQLError(error);
    }
  } else {
    const { error } = await store.createMovement({
      storageFromId: store.currentStorage!.id,
      containerToId: scannedItem.storageTo.id,
      storageUnitId: scannedItem.storageUnit.id,
      amount:        scannedAmount,
    });

    if (error) {
      fillErrorsFromGraphQLError(error);
    }
  }

  if (primaryError.value) {
    return;
  }

  scannedItem.takenAmount += scannedAmount;

  const selectionOrderItem = store.selectedItem.selectionOrder?.items.find(i => i.id === scannedItem!.id);

  if (selectionOrderItem) {
    selectionOrderItem.selectedAmount += scannedAmount;
  }

  if (scannedItem.plannedAmount > 1) {
    speaker.speak(t('{amount} out of {total}', {
      amount: scannedItem.takenAmount,
      total:  scannedItem.plannedAmount,
    }));
  }

  if (scannedItem.takenAmount === scannedItem.plannedAmount) {
    lastScannedPack.value = null;
    if (store.containers.length > 1) {
      speaker.speak(scannedItem.storageTo.name);
    }
  }
  store.adjustScannedItemPosition(scannedItem);

  if (store.currentStorageFullySelected) {
    store.currentStorage = null;
  }

  emit('scan');
}

const editingProduct = ref<Product | null>(null);

const scanField: Ref<InstanceType<typeof BaseScanField>> = ref(null!);

defineExpose({
  scan: (value: string) => scanField.value?.scan(value),
});

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml" />
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml" />
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml" />
<i18n lang="yaml">
ru:
  Remove Item?: Удалить элемент?

en:
  Remove Item?: Remove Item?
</i18n>

