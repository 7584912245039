<template>
  <div class="column">
    <div class="col column justify-center">
      <TransferScanSecondStorageField
        :expected-storage="expectedStorage"
      />
    </div>

    <Teleport to="#teleport-target-buttons-row">
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <KeyboardToggleButton
          v-bind="buttonProps"
          :disable="keyboardDisabled"
        />
        <QBtn
          v-bind="buttonProps"
          icon="mdi-close-circle-outline"
          @click="emit('cancel')"
        >
          {{ t('Cancel') }}
        </QBtn>
        <TransferHistoryButton
          v-bind="buttonProps"
          @click="emit('show-history')"
        />
        <QBtn
          v-if="store.transfer!.byTask"
          v-bind="buttonProps"
          icon="mdi-format-list-checks"
          @click="emit('show-task')"
        >
          {{ t('Task') }}
        </QBtn>
      </ButtonsRow>
    </Teleport>
  </div>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import useOmniInput from '@/composables/useOmniInput';
import useTransferProcessStore from '@/stores/transferProcess';
import TransferHistoryButton from '@/views/Mobile/Transfer/TransferHistoryButton.vue';
import TransferScanSecondStorageField
  from '@/views/Mobile/Transfer/TransferScanSecondStorageField.vue';
import { useI18n } from 'vue-i18n';
import type { Storage } from '@/graphql/types';

const store = useTransferProcessStore();

const { t } = useI18n();

defineProps<{
  keyboardDisabled: boolean;
  expectedStorage: Storage | null;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'show-history'): void;
  (e: 'show-task'): void;
}>();

const { KeyboardToggleButton }  = useOmniInput({ skip: true });

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/speaking.yaml"></i18n>
