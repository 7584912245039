<template>
  <QItem
    v-bind="$attrs"
    class="list-item-default-height"
  >
    <QItemSection class="col-grow">
      <QItemLabel>
        <QIcon
          v-if="props.transfer.byTask"
          name="mdi-format-list-checks"
          size="16px"
          class="bg-blue text-white"
        />
        {{ t('# {text}', {text: transfer.id}) }}
      </QItemLabel>
      <QItemLabel>
        {{ formatDate(transfer.createdAt, FORMATS.DATETIME) }}
      </QItemLabel>
      <QItemLabel>
        {{ t('{n} products', R.uniqBy(m => m.storable.productPack.id, transfer.movements).length) }}
        {{ completionProgress }}
      </QItemLabel>
    </QItemSection>
    <QItemSection
      side
      class="dense-labels justify-between"
    >
      <QItemLabel>
        {{ storageFrom?.name ?? '' }}
      </QItemLabel>
      <QItemLabel>
        {{ storageTo?.name ?? '' }}
      </QItemLabel>
      <QItemLabel>
        <QChip
          :color="stateColor"
          text-color="black"
          dense
          class="q-ma-none"
        >
          {{ t(`transferState.${transfer.state}`) }}
        </QChip>
      </QItemLabel>
    </QItemSection>
  </QItem>
</template>

<script setup lang="ts">

import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type { Transfer } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as R from 'ramda';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { TransferStateEnum } from '@/graphql/types';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  transfer: Transfer;
}>();

const stateColor = computed(() => badgeColors.forOperationState(props.transfer));

const storageTo = computed(() => props.transfer.movements[0]?.storageTo);

const storageFrom = computed(
  () => props.transfer.byTask ? props.transfer.items[0].storageFrom : props.transfer.movements[0]?.storageFrom,
);

const completionProgress = computed(() => {
  if (!props.transfer.byTask) {
    return '';
  }

  const transferred = props.transfer?.items
    .map(i => i.transferredAmount)
    .reduce((sum, amount) => sum + amount, 0);

  const available = props.transfer.state === TransferStateEnum.CREATED
    ? R.sum(props.transfer.items.map(i => i.plannedAmount))
    : props.transfer.locks
      .map(l => l.amount)
      .reduce((sum, amount) => sum + amount, 0);

  return available ? `(${transferred} / ${available})` : '';
});

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/inventory.yaml"></i18n>
